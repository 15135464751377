import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "src/ui-treact/components/misc/Headings.js";
import TeamIllustrationSrc from "src/ui-treact/images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "src/ui-treact/images/dot-pattern.svg";

const Container = tw.div`relative w-full z-10`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 px-8`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.text_on_left ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled.div(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({data,page}) => {
  // The text_on_left boolean prop can be used to display either the text on left or right side of the image.
  let finalData = data?.schema_json;  
  let content= {
    ...finalData
}  
// console.log('finalData', finalData)
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image src={content.img_src} imageBorder={content.imageBorder} imageShadow={content.imageShadow} imageRounded={content.imageRounded} />
          {content.imageDecoratorBlob && <DecoratorBlob css={content.decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn text_on_left={content.text_on_left}>
          <TextContent>
            <Subheading>{content.sub_heading}</Subheading>
            <Heading dangerouslySetInnerHTML={{ __html: content.heading }}></Heading>
            <Steps>
              {content.steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
